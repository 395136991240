// localSlice.js
import { createSlice } from "@reduxjs/toolkit";

export const localSlice = createSlice({
  name: "local",
  initialState: {
    token: localStorage.getItem("mindleagueTokenAdmin") || null,
    userid: localStorage.getItem("mindleagueAdminloginId") || null,
    usertype: localStorage.getItem("mindlagueusertype") || "",
    header: null,
    profiledrop: null,
    coachType: localStorage.getItem("coachtype") || "Team",
    tabType: "",
    school: localStorage.getItem("school") || "",
    helpType: "Query",
    ticketId: localStorage.getItem("ticketId") || null,
    subscriptionType: localStorage.getItem("subscriptionType") || "student",
    sponshorType: localStorage.getItem("sponshorType") || "Team",

    paymentData: localStorage.getItem("paymentData") || null,
    backButtonId: localStorage.getItem("backButtonId") || "",
    managerId: localStorage.getItem("managerId") || "",
    ticketData: localStorage.getItem("ticketData") || null,
    emailAuth: localStorage.getItem("emailAuth") || null,
  },
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
      localStorage.setItem("mindleagueTokenAdmin", action.payload);
    },
    setUserId: (state, action) => {
      state.userid = action.payload;
      localStorage.setItem("mindleagueAdminloginId", action.payload);
    },
    setTicketId: (state, action) => {
      state.ticketId = action.payload;
      localStorage.setItem("ticketId", action.payload);
    },
    setBackButtonId: (state, action) => {
      state.backButtonId = action.payload;
      localStorage.setItem("backButtonId", action.payload);
    },
    setManagerId: (state, action) => {
      state.managerId = action.payload;
      localStorage.setItem("managerId", action.payload);
    },
    setUserType: (state, action) => {
      state.usertype = action.payload;
      localStorage.setItem("mindlagueusertype", action.payload);
    },
    setCommissionerEmail: (state, action) => {
      state.emailAuth = action.payload;
      localStorage.setItem("emailAuth", action.payload);
    },
    setHeader: (state, action) => {
      state.header = action.payload;
    },
    setProfiledropdown: (state, action) => {
      state.profiledrop = action.payload;
    },
    setCoachType: (state, action) => {
      state.coachType = action.payload;
      localStorage.setItem("coachtype", action.payload);
    },
    setTabType: (state, action) => {
      state.tabType = action.payload;
    },
    setHelpType: (state, action) => {
      state.helpType = action.payload;
    },
    setSubscriptionType: (state, action) => {
      state.subscriptionType = action.payload;
      localStorage.setItem("subscriptionType", action.payload);
    },
    setSponshorType: (state, action) => {
      state.sponshorType = action.payload;
      localStorage.setItem("sponshorType", action.payload);
    },
    setSchool: (state, action) => {
      state.school = action.payload;
      localStorage.setItem("school", action.payload);
    },

    setPaymentData: (state, action) => {
      state.paymentData = action.payload;
      localStorage.setItem("paymentData", JSON.stringify(action.payload));
      console.log("action.payload", action.payload);
    },
    setTicketData: (state, action) => {
      state.ticketData = action.payload;
      localStorage.setItem("ticketData", JSON.stringify(action.payload));
      console.log("action.payload", action.payload);
    },
  },
});

export const {
  setToken,
  setUserId,
  setPaymentData,
  setHeader,
  setCommissionerEmail,
  setProfiledropdown,
  setUserType,
  setCoachType,
  setSponshorType,
  setSubscriptionType,
  setTabType,
  setTicketData,
  setHelpType,
  setTicketId,
  setSchool,
  setBackButtonId,
  setManagerId,
} = localSlice.actions;
export default localSlice.reducer;
