import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const PostApicommissioner = createApi({
  reducerPath: "PostApicommissioner",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_APIENDPOINT}`,
  }),
  endpoints: (builder) => ({
    commissionerLogin: builder.mutation({
      query: (body) => {
        return {
          url: "auth/login",
          method: "PUT",
          body,
          headers: {
            "x-auth-user-type": "commissioner",
          },
        };
      },
    }),
    changePassword: builder.mutation({
      query: (body) => {
        const { ecomBuffettoken, ...data } = body;
        return {
          url: "api/admin/updatePassword",
          method: "PATCH",
          body: data,
          headers: {
            "x-auth-token-admin": ecomBuffettoken,
          },
        };
      },
    }),
    updateProfile: builder.mutation({
      query: ({ admintoken, alldata }) => ({
        url: `user/admin/editProfile`,
        method: "PUT",
        body: alldata,
        headers: {
          "x-auth-user-type": "commissioner",
          "x-auth-token-user": admintoken,
        },
      }),
    }),
    updatePassword: builder.mutation({
      query: ({ admintoken, alldata }) => ({
        url: `auth/changePassword`,
        method: "PUT",
        body: alldata,
        headers: {
          "x-auth-user-type": "commissioner",
          "x-auth-token-user": admintoken,
        },
      }),
    }),
    getCommissionerProfile: builder.query({
      query: (body) => {
        const { admintoken, ...data } = body;

        return {
          url: "user/getMyProfile",
          method: "GET",
          // body: data,
          headers: {
            "x-auth-user-type": "commissioner",
            "x-auth-token-user": admintoken,
          },
        };
      },
    }),
    viewContent: builder.query({
      query: (body) => {
        const { admintoken, id, ...data } = body;

        return {
          url: `account/viewContent/${id}`,
          method: "GET",
          // body: data,
          headers: {
            "x-auth-user-type": "commissioner",
            "x-auth-token-user": admintoken,
          },
        };
      },
    }),

    readNotifications: builder.mutation({
      query: (body) => {
        const { id, admintoken } = body;

        return {
          url: `notification/readNotifications`,
          method: "GET",
          // body: data,
          headers: {
            "x-auth-user-type": "commissioner",
            "x-auth-token-user": admintoken,
          },
        };
      },
    }),

    getAllCheats: builder.mutation({
      query: (body) => {
        const { admintoken, ...data } = body;
        return {
          url: "game/getAllCheats",
          method: "PATCH",
          body: data,
          headers: {
            "x-auth-user-type": "commissioner",
            "x-auth-token-user": admintoken,
          },
        };
      },
    }),

    viewCheat: builder.mutation({
      query: (body) => {
        const { id, admintoken } = body;

        return {
          url: `game/viewCheat/${id}`,
          method: "GET",
          headers: {
            "x-auth-user-type": "commissioner",
            "x-auth-token-user": admintoken,
          },
        };
      },
    }),
    getUserView: builder.query({
      query: (body) => {
        const { ecomFiveAdmintoken, ...data } = body;

        return {
          url: "web_api/single-user",
          method: "POST",
          body: data,
          headers: {
            "x-access-token": ecomFiveAdmintoken,
          },
        };
      },
    }),
    getUserViews: builder.query({
      query: (body) => {
        const { admintoken, ...data } = body;

        return {
          url: "user/getMyProfile",
          method: "GET",
          // body: data,
          headers: {
            "x-auth-user-type": "commissioner",
            "x-auth-token-user": admintoken,
          },
        };
      },
    }),
    commissionerdashboard: builder.query({
      query: (body) => {
        const { admintoken, ...data } = body;

        return {
          url: "analytics/commissioner/getStats",
          method: "GET",
          // body: data,
          headers: {
            "x-auth-user-type": "commissioner",
            "x-auth-token-user": admintoken,
          },
        };
      },
    }),
    viewCoach: builder.query({
      query: (body) => {
        const { admintoken, id, ...data } = body;

        return {
          url: `user/admin/viewUser/${id}`,
          method: "GET",
          // body: data,
          headers: {
            "x-auth-user-type": "commissioner",
            "x-auth-token-user": admintoken,
          },
        };
      },
    }),
    viewUser: builder.mutation({
      query: (body) => {
        const { id, admintoken } = body;

        return {
          url: `user/admin/viewUser/${id}`,
          method: "GET",
          headers: {
            "x-auth-user-type": "commissioner",
            "x-auth-token-user": admintoken,
          },
        };
      },
    }),

    viewTournament: builder.mutation({
      query: ({ id, ecomBuffetAdmintoken }) => ({
        url: `tournament/viewTournament/${id}`,
        method: "GET",
        // body: alldata,
        headers: {
          "x-auth-token-admin": ecomBuffetAdmintoken,
        },
      }),
    }),
    getBoard: builder.mutation({
      query: ({ ecomBuffetAdmintoken }) => ({
        url: `user/getBoards`,
        method: "GET",
        // body: alldata,
        headers: {
          "x-auth-token-admin": ecomBuffetAdmintoken,
        },
      }),
    }),
    getLeagues: builder.query({
      query: (body) => {
        const { admintoken, ...data } = body;

        return {
          url: "tournament/getLeagues",
          method: "PATCH",
          body: data,
          headers: {
            "x-auth-user-type": "commissioner",
            "x-auth-token-user": admintoken,
          },
        };
      },
    }),
    getNotification: builder.query({
      query: (body) => {
        const { admintoken, ...data } = body;

        return {
          url: "notification/getPushNotifications",
          method: "PATCH",
          body: data,
          headers: {
            "x-auth-user-type": "commissioner",
            "x-auth-token-user": admintoken,
          },
        };
      },
    }),
    getCoach: builder.query({
      query: (body) => {
        const { admintoken, ...data } = body;

        return {
          url: "user/admin/getUsers",
          method: "PATCH",
          body: data,
          headers: {
            "x-auth-user-type": "commissioner",
            "x-auth-token-user": admintoken,
          },
        };
      },
    }),
    getSponsor: builder.query({
      query: (body) => {
        const { admintoken, ...data } = body;

        return {
          url: "tournament/getSponsors",
          method: "PATCH",
          body: data,
          headers: {
            "x-auth-user-type": "commissioner",
            "x-auth-token-user": admintoken,
          },
        };
      },
    }),
    getTournament: builder.query({
      query: (body) => {
        const { admintoken, ...data } = body;

        return {
          url: "tournament/getAllTournaments",
          method: "PATCH",
          body: data,
          headers: {
            "x-auth-user-type": "commissioner",
            "x-auth-token-user": admintoken,
          },
        };
      },
    }),
    changeTicketStatus: builder.mutation({
      query: (body) => {
        const { admintoken, id, ...data } = body;

        return {
          url: `account/changeStatus/${id}`,
          method: "PATCH",
          body: data,
          headers: {
            "x-auth-user-type": "commissioner",
            "x-auth-token-user": admintoken,
          },
        };
      },
    }),

    helpQuery: builder.query({
      query: (body) => {
        const { admintoken, ...data } = body;

        return {
          url: "account/getTickets",
          method: "PATCH",
          body: data,
          headers: {
            "x-auth-user-type": "commissioner",
            "x-auth-token-user": admintoken,
          },
        };
      },
    }),

    getAllCoaches: builder.query({
      query: (body) => {
        const { admintoken, ...data } = body;

        return {
          url: "coaching/getAllCoaches",
          method: "PATCH",
          body: data,
          headers: {
            "x-auth-user-type": "commissioner",
            "x-auth-token-user": admintoken,
          },
        };
      },
    }),
    getTransaction: builder.query({
      query: (body) => {
        const { admintoken, ...data } = body;

        return {
          url: "/payment/getLeagueTransactions",
          method: "PATCH",
          body: data,
          headers: {
            "x-auth-user-type": "commissioner",
            "x-auth-token-user": admintoken,
          },
        };
      },
    }),
    getRegistredSchool: builder.query({
      query: (body) => {
        const { admintoken, id, ...data } = body;

        return {
          url: `tournament/getLeagueSchools/${id}`,
          method: "PATCH",
          body: data,
          headers: {
            "x-auth-user-type": "commissioner",
            "x-auth-token-user": admintoken,
          },
        };
      },
    }),
    getContent: builder.query({
      query: (body) => {
        const { admintoken, ...data } = body;

        return {
          url: `account/getContent`,
          method: "PATCH",
          body: data,
          headers: {
            "x-auth-user-type": "commissioner",
            "x-auth-token-user": admintoken,
          },
        };
      },
    }),
    viewCoachClient: builder.mutation({
      query: (body) => {
        const { admintoken, ...data } = body;

        return {
          url: "coaching/viewCoachClient",
          method: "PATCH",
          body: data,
          headers: {
            "x-auth-user-type": "commissioner",
            "x-auth-token-user": admintoken,
          },
        };
      },
    }),
    getCommissionerPayouts: builder.query({
      query: (body) => {
        const { admintoken, ...data } = body;

        return {
          url: `subscription/getCommissionerPayouts`,
          method: "PATCH",
          body: data,
          headers: {
            "x-auth-user-type": "commissioner",
            "x-auth-token-user": admintoken,
          },
        };
      },
    }),
    getMySessions: builder.mutation({
      query: ({ admintoken, ...data }) => ({
        url: "coaching/getMySessions",
        method: "PATCH",
        body: data,
        headers: {
          "x-auth-user-type": "commissioner",
          "x-auth-token-user": admintoken,
        },
      }),
    }),
    getMatchDetails: builder.mutation({
      query: (body) => {
        const { id, admintoken } = body;

        return {
          url: `game/getMatchDetails/${id}`,
          method: "GET",
          headers: {
            "x-auth-user-type": "commissioner",
            "x-auth-token-user": admintoken,
          },
        };
      },
    }),
    readNotificationId: builder.mutation({
      query: (body) => {
        const { id, admintoken } = body;

        return {
          url: `notification/readNotification/${id}`,
          method: "GET",
          // body: data,
          headers: {
            "x-auth-user-type": "commissioner",
            "x-auth-token-user": admintoken,
          },
        };
      },
    }),
    viewLeague: builder.mutation({
      query: (body) => {
        const { id, admintoken } = body;

        return {
          url: `tournament/viewLeague/${id}`,
          method: "GET",
          // body: data,
          headers: {
            "x-auth-user-type": "commissioner",
            "x-auth-token-user": admintoken,
          },
        };
      },
    }),
    viewTicketReply: builder.query({
      query: (body) => {
        const { admintoken, id, ...data } = body;

        return {
          url: `account/viewTicket/${id}`,
          method: "GET",
          // body: data,
          headers: {
            "x-auth-user-type": "commissioner",
            "x-auth-token-user": admintoken,
          },
        };
      },
    }),
    schoolList: builder.mutation({
      query: (body) => {
        const { id, admintoken } = body;

        return {
          url: `user/getSchoolsList`,
          method: "GET",
          // body: data,
          headers: {
            "x-auth-user-type": "commissioner",
            "x-auth-token-user": admintoken,
          },
        };
      },
    }),
    countryCode: builder.mutation({
      query: (body) => {
        const { id, admintoken } = body;

        return {
          url: `/user/getCountries`,
          method: "GET",
          // body: data,
          headers: {
            "x-auth-user-type": "commissioner",
            "x-auth-token-user": admintoken,
          },
        };
      },
    }),
    sposnorList: builder.mutation({
      query: (body) => {
        const { id, admintoken } = body;

        return {
          url: `tournament/getSponsorList`,
          method: "GET",
          // body: data,
          headers: {
            "x-auth-user-type": "commissioner",
            "x-auth-token-user": admintoken,
          },
        };
      },
    }),
    getEarning: builder.mutation({
      query: (body) => {
        const { id, admintoken } = body;

        return {
          url: `/payment/getCommissionerEarnings`,
          method: "GET",
          // body: data,
          headers: {
            "x-auth-user-type": "commissioner",
            "x-auth-token-user": admintoken,
          },
        };
      },
    }),
    changeStatus: builder.mutation({
      query: (body) => {
        const { id, admintoken } = body;

        return {
          url: `tournament/changeStatus/${id}`,
          method: "GET",
          // body: data,
          headers: {
            "x-auth-user-type": "commissioner",
            "x-auth-token-user": admintoken,
          },
        };
      },
    }),
    changeStatusCoach: builder.mutation({
      query: (body) => {
        const { id, admintoken } = body;

        return {
          url: `user/admin/changeStatus/${id}`,
          method: "GET",
          // body: data,
          headers: {
            "x-auth-user-type": "commissioner",
            "x-auth-token-user": admintoken,
          },
        };
      },
    }),
    deleteCoach: builder.mutation({
      query: (body) => {
        const { id, admintoken } = body;

        return {
          url: `user/admin/deleteUser/${id}`,
          method: "GET",
          // body: data,
          headers: {
            "x-auth-user-type": "commissioner",
            "x-auth-token-user": admintoken,
          },
        };
      },
    }),
    addLeague: builder.mutation({
      query: ({ formData, admintoken }) => ({
        url: "tournament/addLeague",
        method: "POST",
        body: formData,
        headers: {
          "x-auth-user-type": "commissioner",
          "x-auth-token-user": admintoken,
        },
      }),
    }),
    addSponsor: builder.mutation({
      query: ({ formData, admintoken }) => ({
        url: "tournament/addSponsor",
        method: "POST",
        body: formData,
        headers: {
          "x-auth-user-type": "commissioner",
          "x-auth-token-user": admintoken,
        },
      }),
    }),
    createNotification: builder.mutation({
      query: ({ formData, admintoken }) => ({
        url: "notification/createPushNotifictaion",
        method: "POST",
        body: formData,
        headers: {
          "x-auth-user-type": "commissioner",
          "x-auth-token-user": admintoken,
        },
      }),
    }),
    getCoachClients: builder.mutation({
      query: (body) => {
        const { admintoken, ...data } = body;

        return {
          url: "coaching/getCoachClients",
          method: "PATCH",
          body: data,
          headers: {
            "x-auth-user-type": "commissioner",
            "x-auth-token-user": admintoken,
          },
        };
      },
    }),
    addTicket: builder.mutation({
      query: ({ formData, admintoken }) => ({
        url: "account/raiseTicket",
        method: "POST",
        body: formData,
        headers: {
          "x-auth-user-type": "commissioner",
          "x-auth-token-user": admintoken,
        },
      }),
    }),
    editLeague: builder.mutation({
      query: ({ formData, id, admintoken }) => ({
        url: `tournament/editLeague/${id}`,
        method: "PUT",
        body: formData,
        headers: {
          "x-auth-user-type": "commissioner",
          "x-auth-token-user": admintoken,
        },
      }),
    }),
    changeNotificationSettings: builder.mutation({
      query: ({ formData, admintoken, userType = "commissioner" }) => ({
        url: `notification/changeNotificationSettings`,
        method: "PUT",
        body: formData,
        headers: {
          "x-auth-user-type": userType,
          "x-auth-token-user": admintoken,
        },
      }),
    }),

    queryReply: builder.mutation({
      query: (body) => {
        const { admintoken, ...data } = body;
        return {
          url: "/account/ticketReply",
          method: "PUT",
          body: data,
          headers: {
            "x-auth-user-type": "commissioner",
            "x-auth-token-user": admintoken,
          },
        };
      },
    }),
    viewTicket: builder.mutation({
      query: (body) => {
        const { id, admintoken } = body;

        return {
          url: `account/viewTicket/${id}`,
          method: "GET",
          // body: data,
          headers: {
            "x-auth-user-type": "commissioner",
            "x-auth-token-user": admintoken,
          },
        };
      },
    }),
    deleteUser: builder.mutation({
      query: ({ id, admintoken }) => ({
        url: `user/admin/deleteUser/${id}`,
        method: "GET",
        headers: {
          "x-auth-user-type": "commissioner",
          "x-auth-token-user": admintoken,
        },
      }),
    }),
    deleteSponsorCommi: builder.mutation({
      query: ({ id, admintoken }) => ({
        url: `tournament/deleteSponsor/${id}`,
        method: "GET",
        headers: {
          "x-auth-user-type": "commissioner",
          "x-auth-token-user": admintoken,
        },
      }),
    }),
    getSchedules: builder.mutation({
      query: (body) => {
        const { admintoken, allData } = body;

        return {
          url: `tournament/getSchedules`,
          method: "PATCH",
          body: allData,
          headers: {
            "x-auth-user-type": "commissioner",
            "x-auth-token-user": admintoken,
          },
        };
      },
    }),
    deleteNotificationSingle: builder.mutation({
      query: ({ id, admintoken }) => ({
        url: `notification/deleteNotification/${id}`,
        method: "DELETE",
        headers: {
          "x-auth-user-type": "commissioner",
          "x-auth-token-user": admintoken,
        },
      }),
    }),
    deleteLeague: builder.mutation({
      query: ({ id, admintoken }) => ({
        url: `tournament/deleteLeague/${id}`,
        method: "DELETE",
        headers: {
          "x-auth-user-type": "commissioner",
          "x-auth-token-user": admintoken,
        },
      }),
    }),
    removeSponsor: builder.mutation({
      query: ({ id, admintoken, sponsorId }) => ({
        url: `tournament/removeSponsor/${id}`,
        method: "PATCH",
        body: { sponsorId },
        headers: {
          "x-auth-user-type": "commissioner",
          "x-auth-token-user": admintoken,
        },
      }),
    }),

    viewSponsor: builder.mutation({
      query: (body) => {
        const { id, admintoken } = body;

        return {
          url: `tournament/viewSponsor/${id}`,
          method: "GET",
          // body: data,
          headers: {
            "x-auth-user-type": "commissioner",
            "x-auth-token-user": admintoken,
          },
        };
      },
    }),

    getNotifications: builder.mutation({
      query: (body) => {
        const { admintoken, ...data } = body;

        return {
          url: `notification/getNotifications`,
          method: "PATCH",
          body: data,
          headers: {
            "x-auth-user-type": "commissioner",
            "x-auth-token-user": admintoken,
          },
        };
      },
    }),

    deleteNotifications: builder.mutation({
      query: ({ id, admintoken }) => ({
        url: `notification/deleteNotifications`,
        method: "DELETE",
        headers: {
          "x-auth-user-type": "commissioner",
          "x-auth-token-user": admintoken,
        },
      }),
    }),

    getAllMatches: builder.mutation({
      query: ({ admintoken, ...data }) => ({
        url: "player/getAllMatches",
        method: "PATCH",
        body: data,
        headers: {
          "x-auth-user-type": "commissioner",
          "x-auth-token-user": admintoken,
        },
      }),
    }),

    tournamentchangeStatus: builder.mutation({
      query: (body) => {
        const { id, admintoken } = body;

        return {
          url: `tournament/changeStatus/${id}`,
          method: "GET",
          // body: data,
          headers: {
            "x-auth-user-type": "commissioner",
            "x-auth-token-user": admintoken,
          },
        };
      },
    }),
  }),
});

export const {
  useChangePasswordMutation,
  useUpdateProfileMutation,
  useGetUserViewQuery,
  useCommissionerdashboardQuery,
  useGetLeaguesQuery,
  useViewLeagueMutation,
  useChangeStatusMutation,
  useDeleteUserMutation,
  useAddLeagueMutation,
  useDeleteLeagueMutation,
  useEditLeagueMutation,
  useSchoolListMutation,
  useGetCoachQuery,
  useViewCoachQuery,
  useDeleteCoachMutation,
  useChangeStatusCoachMutation,
  useCreateNotificationMutation,
  useAddSponsorMutation,
  useGetSponsorQuery,
  useSposnorListMutation,
  useGetTournamentQuery,
  useViewTournamentMutation,
  useGetCommissionerProfileQuery,
  useUpdatePasswordMutation,
  useGetRegistredSchoolQuery,
  useGetContentQuery,
  useViewContentQuery,
  useHelpQueryQuery,
  useAddTicketMutation,
  useViewTicketReplyQuery,
  useChangeTicketStatusMutation,
  useGetNotificationQuery,
  useCountryCodeMutation,
  useQueryReplyMutation,
  useViewTicketMutation,
  useDeleteSponsorCommiMutation,
  useGetBoardMutation,
  useGetEarningMutation,
  useGetTransactionQuery,
  useChangeNotificationSettingsMutation,
  useGetCommissionerPayoutsQuery,
  useGetMySessionsMutation,
  useRemoveSponsorMutation,
  useTournamentchangeStatusMutation,
  useGetSchedulesMutation,
  useGetAllMatchesMutation,
  useViewSponsorMutation,
  useViewUserMutation,
  useGetAllCheatsMutation,
  useViewCheatMutation,
  useReadNotificationsMutation,
  useGetNotificationsMutation,
  useGetUserViewsQuery,
  useDeleteNotificationSingleMutation,
  useDeleteNotificationsMutation,
  useGetCoachClientsMutation,
  useViewCoachClientMutation,
  useGetAllCoachesQuery,
  useReadNotificationIdMutation,
  useCommissionerLoginMutation,
  useGetMatchDetailsMutation,
} = PostApicommissioner;
