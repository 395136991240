import { Route, Routes } from "react-router-dom";
import { Suspense, lazy } from "react";
import ProtectedRoute from "./ProtectedRoute";
import Loader from "../loader/Loader";

const Adminlogin = lazy(() => import("../components/admin/Adminlogin"));
const ForgetPassword = lazy(() => import("../components/admin/ForgetPassword"));
const Varification = lazy(() => import("../components/admin/Varification"));
const ResetPassword = lazy(() => import("../components/admin/ResetPassword"));
const Language = lazy(() => import("../components/admin/Language"));
const Dashboard = lazy(() => import("../components/dashboard/Dashboard"));
const DashboardDetails = lazy(() =>
  import("../components/dashboard/DashboardDetails")
);
const CertificateDetails = lazy(() =>
  import("../components/dashboard/CertificateDetails")
);
const StudentPlayersManagement = lazy(() =>
  import("../components/studentsplayers/StudentPlayersManagement")
);
const EditSession = lazy(() => import("../components/coach/EditSession"));
const StudentPlayerDetails = lazy(() =>
  import("../components/studentsplayers/StudentPlayerDetails")
);
const LeagueManagement = lazy(() =>
  import("../components/league/LeagueManagement")
);
const TeamsCityLeauges = lazy(() =>
  import(
    "../components/league/LeagueTeamsTournament/CityLeagues/TeamsCityLeauges"
  )
);
const Teampastcityleagues = lazy(() =>
  import(
    "../components/league/LeagueTeamsTournament/CityLeagues/Teampastcityleagues"
  )
);
const Teamsregionalleauges = lazy(() =>
  import(
    "../components/league/LeagueTeamsTournament/RegionalLeagues/Teamsregionalleauges"
  )
);
const Teamspastleaguedetails = lazy(() =>
  import(
    "../components/league/LeagueTeamsTournament/RegionalLeagues/Teamspastleaguedetails"
  )
);
const Individualcityleauges = lazy(() =>
  import(
    "../components/league/LeagueIndividualTournament/CityLeagues/Individualcityleauges"
  )
);
const Individualpastcityleagues = lazy(() =>
  import(
    "../components/league/LeagueIndividualTournament/CityLeagues/Individualpastcityleagues"
  )
);
const Individualregionalleauges = lazy(() =>
  import(
    "../components/league/LeagueIndividualTournament/RegionalLeagues/Individualregionalleauges"
  )
);
const Individualpastleaguedetails = lazy(() =>
  import(
    "../components/league/LeagueIndividualTournament/RegionalLeagues/Individualpastleaguedetails"
  )
);
const SubscriptionlistofSubscribers = lazy(() =>
  import("../components/subscriptionplans/SubscriptionlistofSubscribers")
);
const CoachEdit = lazy(() => import("../components/coach/CoachEdit"));

const Leaugeteamregisteredschool = lazy(() =>
  import(
    "../components/league/LeagueTeamsTournament/CityLeagues/Leaugeteamregisteredschool"
  )
);
const Leaguecommissionerdetails = lazy(() =>
  import(
    "../components/league/LeagueTeamsTournament/CityLeagues/Leaguecommissionerdetails"
  )
);
const Leaugesponsorshipdetails = lazy(() =>
  import(
    "../components/league/LeagueTeamsTournament/CityLeagues/Leaugesponsorshipdetails"
  )
);
const TeamsLeagueUpcomingMatches = lazy(() =>
  import(
    "../components/league/LeagueTeamsTournament/CityLeagues/TeamsLeagueUpcomingMatches"
  )
);
const TeamsLeaguePastMatches = lazy(() =>
  import(
    "../components/league/LeagueTeamsTournament/CityLeagues/TeamsLeaguePastMatches"
  )
);
const LeaugeIndividualRegisteredSchool = lazy(() =>
  import(
    "../components/league/LeagueIndividualTournament/CityLeagues/LeaugeIndividualRegisteredSchool"
  )
);
const IndividualLeagueUpcomingMatches = lazy(() =>
  import(
    "../components/league/LeagueIndividualTournament/CityLeagues/IndividualLeagueUpcomingMatches"
  )
);
const IndividualLeaguePastMatches = lazy(() =>
  import(
    "../components/league/LeagueIndividualTournament/CityLeagues/IndividualLeaguePastMatches"
  )
);
const LeaugeTeamRegisteredStudent = lazy(() =>
  import(
    "../components/league/LeagueTeamsTournament/CityLeagues/LeaugeTeamRegisteredStudent"
  )
);
const LeaugeIndividualRegisteredStudent = lazy(() =>
  import(
    "../components/league/LeagueTeamsTournament/CityLeagues/LeaugeIndividualRegisteredStudent"
  )
);
const LeagueUpcommingMyTeam = lazy(() =>
  import(
    "../components/league/LeagueTeamsTournament/CityLeagues/LeagueUpcommingMyTeam"
  )
);
const LeagueUpcommingOpponentTeam = lazy(() =>
  import(
    "../components/league/LeagueTeamsTournament/CityLeagues/LeagueUpcommingOpponentTeam"
  )
);
const LeaguePastMyTeam = lazy(() =>
  import(
    "../components/league/LeagueTeamsTournament/CityLeagues/LeaguePastMyTeam"
  )
);
const LeaguePastOpponentTeam = lazy(() =>
  import(
    "../components/league/LeagueTeamsTournament/CityLeagues/LeaguePastOpponentTeam"
  )
);
const LeaugeTeamRegisteredStudentNew = lazy(() =>
  import(
    "../components/league/LeagueIndividualTournament/CityLeagues/LeaugeTeamRegisteredStudentNew"
  )
);
const LeaugeIndividualRegisteredStudentNew = lazy(() =>
  import(
    "../components/league/LeagueIndividualTournament/CityLeagues/LeaugeIndividualRegisteredStudentNew"
  )
);
const TeamsRegionalEdit = lazy(() =>
  import(
    "../components/league/LeagueTeamsTournament/RegionalLeagues/TeamsRegionalEdit"
  )
);
const IndividualLeagueEdit = lazy(() =>
  import(
    "../components/league/LeagueIndividualTournament/RegionalLeagues/IndividualLeagueEdit"
  )
);
const AlumniManagement = lazy(() =>
  import("../components/alumnimanagement/AlumniManagement")
);
const AlumniDetails = lazy(() =>
  import("../components/alumnimanagement/AlumniDetails")
);
const TeamManagersManagement = lazy(() =>
  import("../components/teammanagers/TeamManagersManagement")
);
const TeamManagersDetails = lazy(() =>
  import("../components/teammanagers/TeamManagersDetails")
);
const OldTeamManagersDetails = lazy(() =>
  import("../components/teammanagers/OldTeamManagersDetails")
);
const BackupTeamManagers = lazy(() =>
  import("../components/teammanagers/BackupTeamManagers")
);
const OldBackupTeamManagers = lazy(() =>
  import("../components/teammanagers/OldBackupTeamManagers")
);
const CreatedTeamsTable = lazy(() =>
  import("../components/teammanagers/CreatedTeamsTable")
);
const CreatedSchoolIndividualPlayertTournaments = lazy(() =>
  import("../components/teammanagers/CreatedSchoolIndividualPlayertTournaments")
);
const CreatedSchoolChessChampionshipTournaments = lazy(() =>
  import("../components/teammanagers/CreatedSchoolChessChampionshipTournaments")
);
const CreatedAnnualAlumniTournaments = lazy(() =>
  import("../components/teammanagers/CreatedAnnualAlumniTournaments")
);
const CreatedteamsDetails = lazy(() =>
  import("../components/teammanagers/CreatedteamsDetails")
);
const LeaugeEnteredDetails = lazy(() =>
  import("../components/teammanagers/LeaugeEnteredDetails")
);
const TeamSponsorshipDetails = lazy(() =>
  import("../components/teammanagers/TeamSponsorshipDetails")
);
const UpcomingmatchesDetails = lazy(() =>
  import("../components/teammanagers/UpcomingmatchesDetails")
);
const PastmatchesDetails = lazy(() =>
  import("../components/teammanagers/PastmatchesDetails")
);
const SchoolManagement = lazy(() =>
  import("../components/schoolmanagement/SchoolManagement")
);
const CommissionersManagement = lazy(() =>
  import("../components/commissioners/CommissionersManagement")
);
const CoachManagement = lazy(() =>
  import("../components/coach/CoachManagement")
);
const SponserManagement = lazy(() =>
  import("../components/sponsermanagement/SponserManagement")
);
const SubscriptionPlanManagement = lazy(() =>
  import("../components/subscriptionplans/SubscriptionPlanManagement")
);
const PayoutCommissionerManagement = lazy(() =>
  import(
    "../components/payoutmanagement/commissioner/PayoutCommissionerManagement"
  )
);
const PayoutDetails = lazy(() =>
  import("../components/payoutmanagement/commissioner/PayoutDetails")
);
const PayoutCoachDetails = lazy(() =>
  import("../components/payoutmanagement/coach/PayoutCoachDetails")
);
const TransactionManagement = lazy(() =>
  import("../components/transaction/TransactionManagement")
);
const CheatDetectionManagment = lazy(() =>
  import("../components/cheatdetection/CheatDetectionManagment")
);
const CheatFormView = lazy(() =>
  import("../components/cheatdetection/CheatFormView")
);
const HelpSupport = lazy(() =>
  import("../components/helpsupport/query/HelpSupport")
);
const QueryDetails = lazy(() =>
  import("../components/helpsupport/query/QueryDetails")
);
const FeedbacksDetails = lazy(() =>
  import("../components/helpsupport/feedback/FeedbacksDetails")
);
const ContentManagement = lazy(() =>
  import("../components/content/ContentManagement")
);
const ContentDetails = lazy(() =>
  import("../components/content/ContentDetails")
);
const RulesRegulations = lazy(() =>
  import("../components/content/RulesRegulations")
);
const HonorCode = lazy(() => import("../components/content/HonorCode"));
const SchoolDetails = lazy(() =>
  import("../components/schoolmanagement/SchoolDetails")
);
const SchoolFormerTeamManager = lazy(() =>
  import("../components/schoolmanagement/SchoolFormerTeamManager")
);
const SchoolLeaugeTeams = lazy(() =>
  import("../components/schoolmanagement/Leagues/SchoolLeaugeTeams")
);
const SchoolLeaugesIndividual = lazy(() =>
  import("../components/schoolmanagement/Leagues/SchoolLeaugesIndividual")
);
const SchoolChampionshipClub = lazy(() =>
  import("../components/schoolmanagement/clubs/SchoolChampionshipClub")
);
const AlumniChampionship = lazy(() =>
  import("../components/schoolmanagement/clubs/AlumniChampionship")
);
const SchoolAlumniChampionship = lazy(() =>
  import("../components/schoolmanagement/clubs/SchoolAlumniChampionship")
);
const SchoolIndividualPlayer = lazy(() =>
  import("../components/schoolmanagement/clubs/SchoolIndividualPlayer")
);
const SchoolTeamRegisteredStudent = lazy(() =>
  import("../components/schoolmanagement/SchoolTeamRegisteredStudent")
);
const SchoolIndividualRegisteredStudent = lazy(() =>
  import("../components/schoolmanagement/SchoolIndividualRegisteredStudent")
);

const ListOfTeamMembers = lazy(() =>
  import("../components/schoolmanagement/clubs/ListOfTeamMembers")
);
const ListOfSubscribers = lazy(() =>
  import("../components/schoolmanagement/clubs/ListOfSubscribers")
);
const ListOfNonSubscribers = lazy(() =>
  import("../components/schoolmanagement/clubs/ListOfNonSubscribers")
);
const ListOfAlumniMembers = lazy(() =>
  import("../components/schoolmanagement/clubs/ListOfAlumniMembers")
);
const TeamListOfClubMembers = lazy(() =>
  import("../components/schoolmanagement/Leagues/TeamListOfClubMembers")
);
const ViewRequest = lazy(() =>
  import("../components/commissioners/ViewRequest")
);
const ViewRequestDetails = lazy(() =>
  import("../components/commissioners/ViewRequestDetails")
);
const CommissionersAddNew = lazy(() =>
  import("../components/commissioners/CommissionersAddNew")
);
const CommissionersDetails = lazy(() =>
  import("../components/commissioners/CommissionersDetails")
);
const CreatedLeaguesDetails = lazy(() =>
  import("../components/commissioners/CreatedLeaguesDetails")
);
const CommissionersRegisteredSchool = lazy(() =>
  import("../components/commissioners/CommissionersRegisteredSchool")
);
const CommissionersTeamRegisteredStudent = lazy(() =>
  import("../components/commissioners/CommissionersTeamRegisteredStudent")
);
const CommissionersIndividualRegisteredStudent = lazy(() =>
  import("../components/commissioners/CommissionersIndividualRegisteredStudent")
);
const AddedSchoolsDetails = lazy(() =>
  import("../components/commissioners/AddedSchoolsDetails")
);
const AddNewCoach = lazy(() => import("../components/coach/AddNewCoach"));
const TeamCoachDetails = lazy(() =>
  import("../components/coach/team/TeamCoachDetails")
);
const ClientsSchoolDetails = lazy(() =>
  import("../components/coach/team/ClientsSchoolDetails")
);
const TeamStudentAttendance = lazy(() =>
  import("../components/coach/team/TeamStudentAttendance")
);
const TeamCoachSchoolSession = lazy(() =>
  import("../components/coach/team/TeamCoachSchoolSession")
);
const TeamRegisteredStudents = lazy(() =>
  import("../components/coach/team/TeamRegisteredStudents")
);
const TeamRegisteredClubs = lazy(() =>
  import("../components/coach/team/TeamRegisteredClubs")
);
const OldTeamCoachDetails = lazy(() =>
  import("../components/coach/team/OldTeamCoachDetails")
);
const ViewProfile = lazy(() => import("../components/admin/ViewProfile"));
const ChangePassword = lazy(() => import("../components/admin/ChangePassword"));
const Commissionersedit = lazy(() =>
  import("../components/commissioners/Commissionersedit")
);
const AddSponsor = lazy(() =>
  import("../components/sponsermanagement/AddSponsor")
);
const SubscriptionDetails = lazy(() =>
  import("../components/subscriptionplans/SubscriptionDetails")
);
const SubscriptionEdit = lazy(() =>
  import("../components/subscriptionplans/SubscriptionEdit")
);
const PushNotification = lazy(() =>
  import("../components/PushNotifications/PushNotification")
);
const Notifications = lazy(() =>
  import("../components/Notifications/Notifications")
);
const Pay = lazy(() =>
  import("../components/payoutmanagement/commissioner/Pay")
);
const PayCoach = lazy(() =>
  import("../components/payoutmanagement/coach/PayCoach")
);
const SetSessionPrice = lazy(() =>
  import("../components/coach/SetSessionPrice")
);
const SetDiscountCode = lazy(() =>
  import("../components/offermanagement/SetDiscountCode")
);
const EditSetDiscountCode = lazy(() =>
  import("../components/offermanagement/EditSetDiscountCode")
);

const AdminRoutes = () => (
  <Suspense
    fallback={
      <div>
        <Loader />
      </div>
    }
  >
    <Routes>
      <Route path="/login" element={<Adminlogin />} />
      <Route path="/forgot-password" element={<ForgetPassword />} />
      <Route path="/verification" element={<Varification />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/" element={<Language />} />
      <Route path="/view-profile" element={<ViewProfile />} />
      <Route path="/change-password" element={<ChangePassword />} />

      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/dashboard-details/:id" element={<DashboardDetails />} />

      <Route path="/certificate-details/:id" element={<CertificateDetails />} />

      <Route path="/push-notifications" element={<PushNotification />} />
      <Route path="/all-notifications" element={<Notifications />} />

      <Route
        path="/student-players-management"
        element={<StudentPlayersManagement />}
      />
      <Route
        path="/studentplayer-details/:id"
        element={<StudentPlayerDetails />}
      />

      <Route path="/league-management" element={<LeagueManagement />} />
      <Route path="/teams-city-leauges/:id" element={<TeamsCityLeauges />} />
      <Route path="/set-session-price" element={<SetSessionPrice />} />
      <Route
        path="/team-past-city-leagues/:id"
        element={<Teampastcityleagues />}
      />
      <Route
        path="/teams-regional-leauges/:id"
        element={<Teamsregionalleauges />}
      />
      <Route
        path="/teams-past-league-details/:id"
        element={<Teamspastleaguedetails />}
      />
      <Route
        path="/individual-city-leauges/:id"
        element={<Individualcityleauges />}
      />
      <Route
        path="/individual-past-city-leagues/:id"
        element={<Individualpastcityleagues />}
      />
      <Route
        path="/individual-regional-leauges/:id"
        element={<Individualregionalleauges />}
      />
      <Route
        path="/individual-past-league-details/:id"
        element={<Individualpastleaguedetails />}
      />
      <Route
        path="/leauge-team-registered-school/:id"
        element={<Leaugeteamregisteredschool />}
      />
      <Route
        path="/league-commissioner-details/:id"
        element={<Leaguecommissionerdetails />}
      />
      <Route
        path="/leauge-sponsorship-details/:id"
        element={<Leaugesponsorshipdetails />}
      />
      <Route
        path="/teams-league-upcoming-matches/:id"
        element={<TeamsLeagueUpcomingMatches />}
      />
      <Route
        path="/teams-league-past-matches"
        element={<TeamsLeaguePastMatches />}
      />
      <Route
        path="/leauge-individual-registered-school"
        element={<LeaugeIndividualRegisteredSchool />}
      />
      <Route
        path="/individual-league-upcoming-matches"
        element={<IndividualLeagueUpcomingMatches />}
      />
      <Route
        path="/individual-league-past-matches"
        element={<IndividualLeaguePastMatches />}
      />
      <Route
        path="/leauge-team-registered-student/:id"
        element={<LeaugeTeamRegisteredStudent />}
      />
      <Route
        path="/leauge-individual-registered-student/:id"
        element={<LeaugeIndividualRegisteredStudent />}
      />
      <Route
        path="/league-upcomming-my-team/:id"
        element={<LeagueUpcommingMyTeam />}
      />
      <Route
        path="/league-upcomming-opponent-team"
        element={<LeagueUpcommingOpponentTeam />}
      />
      <Route path="/league-past-my-team" element={<LeaguePastMyTeam />} />
      <Route
        path="/league-past-opponent-team"
        element={<LeaguePastOpponentTeam />}
      />
      <Route
        path="/leauge-team-registered-student-new"
        element={<LeaugeTeamRegisteredStudentNew />}
      />
      <Route
        path="/leauge-individual-registered-student-new"
        element={<LeaugeIndividualRegisteredStudentNew />}
      />
      <Route path="/teams-regional-edit" element={<TeamsRegionalEdit />} />
      <Route
        path="/individual-league-edit"
        element={<IndividualLeagueEdit />}
      />

      <Route path="/alumni-management" element={<AlumniManagement />} />
      <Route path="/alumni-details/:id" element={<AlumniDetails />} />

      <Route
        path="/team-managers-management"
        element={<TeamManagersManagement />}
      />
      <Route
        path="/team-managers-details/:id"
        element={<TeamManagersDetails />}
      />
      <Route
        path="/old-/team-managers-details/:id"
        element={<OldTeamManagersDetails />}
      />
      <Route
        path="/backup-team-managers/:id"
        element={<BackupTeamManagers />}
      />
      <Route
        path="/old-/backup-team-managers/:id"
        element={<OldBackupTeamManagers />}
      />
      <Route path="/createdteams-table/:id" element={<CreatedTeamsTable />} />
      <Route
        path="/created-school-individual-playert-tournaments/:id"
        element={<CreatedSchoolIndividualPlayertTournaments />}
      />
      <Route
        path="/created-school-chess-championship-tournaments/:id"
        element={<CreatedSchoolChessChampionshipTournaments />}
      />
      <Route
        path="/created-annual-alumni-tournaments/:id"
        element={<CreatedAnnualAlumniTournaments />}
      />
      <Route
        path="/createdteams-details/:id"
        element={<CreatedteamsDetails />}
      />
      <Route
        path="/leauge-entered-details/:id"
        element={<LeaugeEnteredDetails />}
      />
      <Route
        path="/team-sponsorship-details/:id"
        element={<TeamSponsorshipDetails />}
      />
      <Route
        path="/upcomingmatches-details"
        element={<UpcomingmatchesDetails />}
      />
      <Route path="/pastmatches-details" element={<PastmatchesDetails />} />

      <Route path="/school-management" element={<SchoolManagement />} />
      <Route path="/school-details/:id" element={<SchoolDetails />} />
      <Route
        path="/school-former-team-manager/:id"
        element={<SchoolFormerTeamManager />}
      />
      <Route path="/school-leauge-teams" element={<SchoolLeaugeTeams />} />
      <Route
        path="/school-leauges-individual/:id"
        element={<SchoolLeaugesIndividual />}
      />
      <Route path="/school-championship" element={<SchoolChampionshipClub />} />
      <Route
        path="/school-alumni-championship"
        element={<SchoolAlumniChampionship />}
      />
      <Route
        path="/school-individual-player"
        element={<SchoolIndividualPlayer />}
      />
      <Route
        path="/school-team-registered-student/:id"
        element={<SchoolTeamRegisteredStudent />}
      />
      <Route
        path="/school-individual-registered-student/:id"
        element={<SchoolIndividualRegisteredStudent />}
      />

      <Route path="/list-of-team-members" element={<ListOfTeamMembers />} />
      <Route path="/list-of-subscribers/:id" element={<ListOfSubscribers />} />
      <Route
        path="/list-of-non-subscribers/:id"
        element={<ListOfNonSubscribers />}
      />
      <Route path="/list-of-alumni-members" element={<ListOfAlumniMembers />} />
      <Route
        path="/team-list-of-club-members/:id"
        element={<TeamListOfClubMembers />}
      />

      <Route
        path="/commissioners-management"
        element={<CommissionersManagement />}
      />
      <Route path="/view-request" element={<ViewRequest />} />
      <Route path="/view-request-details" element={<ViewRequestDetails />} />
      <Route path="/commissioners-edit/:id" element={<Commissionersedit />} />
      <Route path="/commissioners-add-new" element={<CommissionersAddNew />} />
      <Route
        path="/commissioners-details/:id"
        element={<CommissionersDetails />}
      />
      <Route
        path="/created-leagues-details/:id"
        element={<CreatedLeaguesDetails />}
      />
      <Route
        path="/commissioners-registered-school"
        element={<CommissionersRegisteredSchool />}
      />

      <Route path="/coach-management" element={<CoachManagement />} />

      <Route path="/add-new-coach" element={<AddNewCoach />} />
      <Route path="/team-coach-details/:id" element={<TeamCoachDetails />} />
      <Route path="/coach-edit/:id" element={<CoachEdit />} />

      <Route
        path="/clients-school-details/:id"
        element={<ClientsSchoolDetails />}
      />
      <Route
        path="/team-student-attendance"
        element={<TeamStudentAttendance />}
      />
      <Route
        path="/team-coach-school-session/:id"
        element={<TeamCoachSchoolSession />}
      />
      <Route
        path="/team-registered-students/:id"
        element={<TeamRegisteredStudents />}
      />
      <Route
        path="/team-registered-clubs/:id"
        element={<TeamRegisteredClubs />}
      />
      <Route
        path="/old-/team-coach-details"
        element={<OldTeamCoachDetails />}
      />

      <Route path="/sponsar-management" element={<SponserManagement />} />

      <Route
        path="/subscription-plan-management"
        element={<SubscriptionPlanManagement />}
      />
      <Route
        path="/subscription-details/:id"
        element={<SubscriptionDetails />}
      />
      <Route path="/subscription-edit/:id" element={<SubscriptionEdit />} />

      <Route
        path="/payout-commissioner-management"
        element={<PayoutCommissionerManagement />}
      />
      <Route path="/pay" element={<Pay />} />
      <Route path="/pay-coach" element={<PayCoach />} />
      <Route path="/payout-details/:id" element={<PayoutDetails />} />
      <Route
        path="/payout-coach-details/:id"
        element={<PayoutCoachDetails />}
      />
      <Route
        path="/commissioners-team-registered-student"
        element={<CommissionersTeamRegisteredStudent />}
      />
      <Route
        path="/commissioners-individual-registered-student"
        element={<CommissionersIndividualRegisteredStudent />}
      />
      <Route
        path="/added-schools-details/:id"
        element={<AddedSchoolsDetails />}
      />

      <Route
        path="/transaction-management"
        element={<TransactionManagement />}
      />

      <Route
        path="/cheat-detection-managment"
        element={<CheatDetectionManagment />}
      />
      <Route path="/cheat-form-view/:id" element={<CheatFormView />} />

      <Route path="/help-support" element={<HelpSupport />} />
      <Route path="/query-details/:id" element={<QueryDetails />} />
      <Route path="/feedbacks-details/:id" element={<FeedbacksDetails />} />

      <Route path="/content-management" element={<ContentManagement />} />
      <Route path="/content-details/:id" element={<ContentDetails />} />
      <Route path="/rules-regulations" element={<RulesRegulations />} />
      <Route path="/honor-code" element={<HonorCode />} />

      <Route path="/add-sponsor" element={<AddSponsor />} />
      {/* <Route path="/edit-session/:id" element={<EditSession />} /> */}
      <Route
        path="/view-session/:id"
        element={<EditSession viewMode={true} />}
      />
      <Route
        path="/edit-session/:id"
        element={<EditSession viewMode={false} />}
      />

      <Route
        path="/subscriber-details/:id"
        element={<SubscriptionlistofSubscribers />}
      />
      <Route path="/discount-management" element={<SetDiscountCode />} />
      <Route
        path="/view-voucher/:id"
        element={<EditSetDiscountCode viewMode={true} />}
      />
      <Route
        path="/edit-voucher/:id"
        element={<EditSetDiscountCode viewMode={false} />}
      />
    </Routes>
  </Suspense>
);

export default AdminRoutes;
